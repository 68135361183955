import { Component } from 'react';
import { Outlet } from 'react-router-dom';

import { UserFormsVerificationInfoDialog } from '../components/Alert/UserFormsVerificationInfoDialog';
import { OVERLAY_CONTAINER_ID } from '../components/OverlayContainer/OverlayContainer';
import { SupportChat } from '../components/SupportChat/SupportChat';
import { Header } from '../components/base/Header';
import { NavBar } from '../components/base/NavBar';
import { Footer } from '../components/base/footer';
import { DesktopMenu } from '../components/base/menu/desktop/DesktopMenu';
import LoadingComponent from '../components/loading/load_panel';
import { localStorageHelper } from '../utils/storage/local_storage';
import { getVisiblePages, getHomePage } from '../utils/menu/menuPages';
import { LOCAL_STORAGE } from '../utils/storage/constants';
import PATH from '../routes/constants';
import { ScrollToTopButton } from '../components/base/scroll_top_button';

export class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    const selectedAccount = localStorageHelper.getSelectedAccount();

    this.state = {
      selectedAccount: selectedAccount,
    };

    this.setSelectedAccount = this.setSelectedAccount.bind(this);
  }

  setSelectedAccount(account) {
    this.setState((state) => ({ ...state, selectedAccount: account }));
  }

  render() {
    const { menuPages, footerPages } = this.props.pages;

    const menuVisiblePages = getVisiblePages(menuPages);

    const userWithAccount = this.props.userAccounts[0]?.ContaID;

    const accessShowDialog = Number(localStorageHelper.getValue(LOCAL_STORAGE.LOGIN_TYPE)) === 0;

    const defaultPage = getHomePage(menuVisiblePages); 

    return (
      <>
        <LoadingComponent isVisible={menuVisiblePages?.length === 0} />

        <>
          <header>
            <Header userInfo={this.props.userInfo} defaultPage={defaultPage}/>

            {userWithAccount &&
            <NavBar
              userInfo={this.props.userInfo}
              userAccounts={this.props.userAccounts}
              menuPages={menuVisiblePages}
              selectedAccount={this.state.selectedAccount}
              onSelectAccount={this.setSelectedAccount}
            />}
          </header>

          <main className='relative flex min-h-[calc(100vh-10rem)] lg:min-h-[calc(100vh-9rem)]'>
            <div
              id={OVERLAY_CONTAINER_ID}
              className='invisible absolute bottom-0 z-50 h-[calc(100%+3.5rem)] w-full'
            />

            {menuVisiblePages.length > 1 && <DesktopMenu pages={menuVisiblePages} />}
            <div
              className='z-0 flex-1 overflow-x-hidden'
            >
              <div
                id='content-body'
                className='p-5 lg:p-10'
              >
                {accessShowDialog && <UserFormsVerificationInfoDialog menuPages={menuPages} />}

                <Outlet context={{ selectedAccount: this.state.selectedAccount }} />
              </div>
            </div>
          </main>

          <div className='noPrint'>
            <ScrollToTopButton />
            <SupportChat />
            <Footer menus={footerPages} />
          </div>

        </>
      </>
    );
  }
}
