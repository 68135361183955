import React from 'react';
import DialogComponent from "../dialog/dialog";
import { caller } from "../../utils/integration/integration";
import SimpleButtonComponent from "../../components/button/simple_button";
import { InputText } from "../../components/input/InputText";
import resourcesConfig from "../../config/api_resources.json";
import { localStorageHelper } from '../../utils/storage/local_storage';
import { InvalidFieldListFeedback } from '../Alert/InvalidFieldListFeedback';
import { getLabel } from'../../utils/translates/authdialog_labels';
import { showError, showSuccess } from'../../utils/notification/showFeedback';
import LoadingComponent from '../loading/load_panel';
import { Icon } from '../Icon/Icon';
import { translate } from '../../utils/translates/translates_helper';
import { InputNumber } from '../input/InputNumber';


const loginTransactions = ["USRLGSTRAUTH", "USRLGSMS", "USRSLSMS"];
export default class AuthDialog extends React.Component {
    constructor(props) {
        super(props);

        this.pinMaxLength = 4;

        this.state = {
            showDialog: false,
            isError: false,
            isLoading: false,
            pin: null,
            dialogData: {
                title: "",
                content: "",
            },
            loginData: {
                phoneNumber: null,
                language: null
            }
        };
        window.authDialog = this;
    }

    setLoginData = (phoneNumber, language) => {
        this.setState({
            loginData: {
                phoneNumber: phoneNumber ?? null,
                language: language ? language.split("-")[1] : null
            }
        });
    }

    ConfirmTransaction = (e) => {
        caller.get(resourcesConfig.TRANSACTION.CONFIRM_TRANSACTION, {
            TransacaoID: this.state.TransacaoID,
            Pin: this.state.pin,
            FatorMnemonica: this.state.FatorMnemonica,
            TransacaoTipo: this.state.TransacaoTipo
        }, false, null, null, true).then(response => {
            if(loginTransactions.includes(this.state.TransacaoTipo)){
                this.cancelFunction();
            }
        }).catch(err => {
            if(loginTransactions.includes(this.state.TransacaoTipo)){
                this.cancelFunction();
            }
        });
    }

    ResendPinTransaction = (e) => {
        this.setState(state => ({
            ...state,
            isLoading: true
        }), function () {
            caller.get(resourcesConfig.TRANSACTION.RESEND_PIN_TRANSACTION, {
                TransacaoID: this.state.TransacaoID,
                TransacaoAutenticacaoID: this.state.TransactionAuthID,
                FlagSMS: 1
            }, false, null, null, true).then(() => {
                this.setState(state => ({
                    ...state,
                    isLoading: false
                }), function () {
                    showSuccess(getLabel("SEG_FACT_RESEND_SUCCESS", this.state.loginData.language));
                });
            });
        });
    }

    ShowConfirmErrorMessage = (e) => {
        if(loginTransactions.includes(this.state.TransacaoTipo)){
            this.cancelFunction();
        }else{
            this.setState({
                dialogData: {
                    title: getLabel("VALIDATION", this.state.loginData.language),
                    content: this.renderDialogContent(true),
                }
            });
        }
    }

    ClearConfirmErrorMessage = (e) => {
        this.setState({
            dialogData: {
                title: getLabel("VALIDATION", this.state.loginData.language),
                content: this.renderDialogContent(false),
            }
        });
    }

    pinChanged = (event) => {
        this.setState({
            pin: event.target.value
        });
    };

    secondFactor = (e) => {
        e = e.replace(/\s*(['"])?([a-z0-9A-Z_\.]+)(['"])?\s*:([^,\}]+)(,)?/g, '"$2": $4$5');
        e = e.replaceAll("'", "\"");
        let authInfo = JSON.parse(e);
        window.authDialog.TransactionID = authInfo.TransactionID;
        this.setState({
            TransacaoID: authInfo.TransactionID,
            FatorMnemonica: authInfo.secondFactorNeeded,
            TransactionAuthID: authInfo.TransactionAuthID,
            TransacaoTipo: authInfo.TransactionType,
            showDialog: true,
            dialogData: {
                title: getLabel("VALIDATION", this.state.loginData.language),
                content: this.renderDialogContent(false),
            }
        });
    }

    handleSubmitPin = (e) => {
        if (this.state.pin) {
            this.ConfirmTransaction();
        } else {
            this.setState(state => ({ 
                ...state,
                dialogData: {
                    ...state.dialogData,
                    content: this.renderDialogContent(true)
                }
            }));
        }
    }

    renderConfirmationPopup = () => {
        return(
            <div id="modelConfirm" className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4 ">
                <div className="relative top-40 mx-auto shadow-xl rounded-md bg-white max-w-md">
                    <div className="p-6 pt-0 text-center">
                        <Icon name="erro" height={80} width={80}/>
                        <h3 className="text-xl font-normal text-gray-500 mt-5 mb-6"> {getLabel("CANCEL_CONFIRMATION_2")} </h3>
                        <div className='flex w-full justify-between'>
                            <SimpleButtonComponent onClick={this.ClearConfirmErrorMessage}>
                                <span>{getLabel("CANCEL")}</span>
                            </SimpleButtonComponent>
                            <SimpleButtonComponent onClick={() => this.closeDialog(false, true)}>
                                <span>{getLabel("YES")}</span>
                            </SimpleButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDialogContent = (isError, showConfirmationPopup = false) => {
        return (
            <> 
                {showConfirmationPopup && this.renderConfirmationPopup()}
                <div className='px-4 py-3 grid gap-4'>
                    <div className='flex flex-col lg:flex-row gap-8'>
                        <div className='w-full lg:w-3/5 flex flex-col gap-2 lg:gap-6'>
                            {isError && <InvalidFieldListFeedback messages={[getLabel("WRONG_CODE", this.state.loginData.language)]} />}
                            
                            <p>{getLabel("SEG_FACT_REQ", this.state.loginData.language)} {localStorageHelper.getUserTransactionMaskedPhone() ?? this.state.loginData.phoneNumber}*.</p>

                            <div className='flex items-center gap-4 w-full'>
                                <InputNumber
                                    onChange={this.pinChanged}
                                    maxLength={this.pinMaxLength}
                                    removeSelectionRule={true}
                                    className='flex-1 w-full'
                                    containerClassName="w-full"
                                />
                                
                                <SimpleButtonComponent
                                    onClick={this.handleSubmitPin} 
                                    variant="primary"
                                    type="submit"
                                    className='w-fit'>
                                    <span>{getLabel("CONFIRM", this.state.loginData.language)}</span>
                                </SimpleButtonComponent>
                            </div>
                            
                            <div>
                                <span>{getLabel("SEG_FACT_MSG_PART1", this.state.loginData.language)}</span>&nbsp;                           
                                <SimpleButtonComponent onClick={this.ResendPinTransaction} variant='link' className='w-fit !inline'>
                                    <span className="text-brand-gray700">{getLabel("SEG_FACT_MSG_PART2", this.state.loginData.language)}</span>
                                </SimpleButtonComponent>.
                            </div>
                        </div>

                        <div className='w-full lg:w-2/5 grid gap-2 text-left'>
                            <p className='text-brand-green500 leading-none pb-2 text-xl sub-title'>{getLabel("AVISOS", this.state.loginData.language)}</p>
                            <p className='font-semibold'>{getLabel("AVISOS_PART1", this.state.loginData.language)}</p>
                            <p>{getLabel("AVISOS_PART2", this.state.loginData.language)}</p>
                            <p>*{getLabel("AVISOS_PART3", this.state.loginData.language)}</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    closeDialog = (isCloseClicked, cancelConfirmed = false) => {
        if(isCloseClicked){
            this.setState({
                showDialog: true,
                dialogData: {
                    content: this.renderDialogContent(false, true),
                }
            });
        } else {
            this.setState({
                showDialog: false,
                dialogData: {
                    title: "",
                    content: "",
                }
            }, function () {
                if (window && window.authDialog && window.authDialog.cancelFunction && cancelConfirmed)
                    window.authDialog.cancelFunction();
            });
        }
    }

    render() {
        return (
            <>
                <LoadingComponent isVisible={this.state.isLoading} />

                <DialogComponent 
                    visible={this.state.showDialog} 
                    callback={this.closeDialog} 
                    dialog={this.state.dialogData} 
                    hideOnOutsideClick={false} 
                    isPrintable={false} 
                    showCloseButton={true}
                />
            </>
        );
    }
}
