import React from "react";

import * as auth from "../../utils/authentication/authentication";
import LoginModal from "./login_modal";
import LoadingComponent from "../../components/loading/load_panel";
import { AuthPageOperation } from "../../components/AuthPageOperation/AuthPageOperation";
import { getLabel, getLink } from "../../utils/translates/login_labels";
import { CookiesBanner } from "./CookiesBanner";
import { caller } from "../../utils/integration/integration";
import resourcesConfig from "../../config/api_resources.json";

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cookiesAllowed: auth.isCookiesAllowed(),
            domain: null
        }
    }

    allowCookies = () => {
        this.setState({
            cookiesAllowed: true
        }, function () {
            auth.allowCookies();
        })
    }

    renderCookieAllowPanel = () => {
        if (!this.state.cookiesAllowed) {
            return <CookiesBanner onClick={this.allowCookies} />;
        }
    }

    CheckDomain = () => {
        if (this.state.domain == null) {
            caller.getWithoutAuth(resourcesConfig.AUTHENTICATION.CHECK_DOMAIN)
                .then(response => {
                    console.log(response)
                    this.setState(state => ({
                        ...state,
                        domain: response.type
                    }));
                }).catch(err => {
                    this.setState(state => ({
                        ...state,
                        domain: ''
                    }));
                });
            return false;
        }
        else return true;
    }

    render = () => {
        if (!this.CheckDomain())
            return <LoadingComponent isVisible={true} customMessage={getLabel("LOADING")} />
        else
            return (
                <div className="overflow-x-hidden">
                    {navigator.cookieEnabled && this.renderCookieAllowPanel()}

                    <AuthPageOperation.Background>
                        <AuthPageOperation.Header />

                        <div className='flex items-center justify-center flex-1'>
                            <LoginModal
                                domainType={this.state.domain}
                                handleAuthentication={this.props?.handleAuthentication}
                            />
                        </div>
                    </AuthPageOperation.Background>
                </div>
            );
    }
}

export default Login;