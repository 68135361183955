import { lazy, Suspense, useEffect } from 'react';
import { Navigate, useLocation, useOutletContext } from 'react-router-dom';

import LoadingComponent from '../components/loading/load_panel';
import { PageTitle } from '../components/title/page_title';
import { PATH } from '../routes/constants';
import { isAuthenticated } from '../utils/authentication/authentication';

export default function ScrollToTop({ pagePath }) {
  useEffect(() => window.scrollTo({ top: 0, behavior: 'instant' }), [pagePath]);

  return null;
}

export function AuthenticatedPage({ accounts, pageCustomParams, pagePath, pageTitle }) {
  const { selectedAccount } = useOutletContext();
  const { pathname } = useLocation();

  if (!isAuthenticated()) {
    return <Navigate to={PATH.LOGOUT} />;
  }

  const Page = lazy(() =>
    import(`../features${pagePath}`).catch(() => import(`../pages/NotFoundPage`))
  );

  const account = accounts.find((account) => account.ContaCodigo === selectedAccount);
  const userAccountId = account?.ContaID ? String(account.ContaID) : null;
  const userAccountCode = account?.ContaCodigo ? String(account.ContaCodigo) : null;

  return (
    <div className='flex flex-col gap-4 lg:gap-5'>
      <PageTitle.Component
        customParams={pageCustomParams}
        title={pageTitle}
      />

      <div id='content-container'>
        <Suspense fallback={<LoadingComponent />}>
          <ScrollToTop pagePath={Date.now() + pagePath} />
          <Page key={Date.now() + pathname} userAccountId={userAccountId} userAccountCode={userAccountCode} />
        </Suspense>
      </div>
    </div>
  );
}
